.user-details {
  height: 950px;
}

.image-preview {
  position: absolute;
  z-index: 1;
  background-color: rgb(0, 0, 0, 0.1) !important;
  text-align: center;
  left: 0;
  width: 70%;
  margin: auto;
  right: 0;
}
