.wrapp {
  height: 100vh;
  border: solid white;
  // background-color: rgb(0, 0, 0, 0.03);
  // background-color: (rgb(62, 118, 223));
  background-image: url("../../assets/images/login-background.jpg");
  // background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  position: relative;

  .content {
    margin-top: 20%;
    position: relative;
    border-top-right-radius: 40px !important;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .show {
    cursor: pointer;
  }

  .btn:hover {
    background-color: rgb(32, 95, 196) !important ;
  }
}
