$primary-orange: #F46A35;
$primary-blue: #1E266D;
$background-blue: #F1F5FE;

.input-container {
    position: relative;
    display: flex;
    align-items: center;


    input {
        width: 100%;
        padding-right: 50px;
    }

    .toggle-password {
        position: absolute;
        right: 0px;
        background: $primary-orange;
        border: none;
        color: #fffcfc;
        cursor: pointer;
        font-size: 0.9rem;
        padding: 14px;
        border-radius: 8px;
    }

    .toggle-password:hover {
        background: $primary-blue;
    }
}

.skeleton-loader-p {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .skeleton-logo-p {
        width: 100%;
        height: 80px;
        background-color: #e0e0e0;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }

    .skeleton-input-p {
        width: 100%;
        height: 43px;
        background-color: #a74747;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }

    .skeleton-button-p {
        width: 100%;
        height: 43px;
        background-color: #e0e0e0;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }
}

@keyframes skeleton-loading-p {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #f5f5f5;
    }

    100% {
        background-color: #e0e0e0;
    }
}