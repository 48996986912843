.history-wrpper {
  height: 900px !important;
  overflow: auto;
  width: 100%;
  margin: auto;
  background-color: white;
  overflow: auto;

  border-radius: 30px;
  .dropdown-menu {
    margin-left: -50px !important;
    margin-top: 10px;
    .dropdown-item {
      width: 20% !important;
    }
  }
  .table-wrapper-h {
    overflow: auto;
  }
  table {
    width: 85%;
    height: 200px;
    overflow-x: auto;
    width: 100px;
    // tbody {
    //   border-width: 1px;
    // }
  }
}
