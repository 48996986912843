.btc-wrapper {
  height: 900px;
  width: 10%;
  margin: auto;
  background-color: white;

  border-radius: 30px;
  .dropdown-menu {
    margin-left: -50px !important;
    margin-top: 10px;
    .dropdown-item {
      width: 20% !important;
    }
  }
  .table-wrapper-h {
    overflow: auto;
  }
  table {
    width: 95%;

    // tbody {
    //   border-width: 1px;
    // }
  }
}
