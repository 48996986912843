.header {
  height: 65px;

  .navigation-button {
    vertical-align: middle;
  }

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 90px;
    line-height: 35px;
    background-color: rgb(229, 236, 248);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }

  .notification {
    position: absolute;
    left: 15px;
    top: -10px;
    background: red;
    border-radius: 120px;
    width: 30px;
    height: 30px;
    padding-top: 2px;
    text-align: center;
    color: white;
  }

  .dropdown-menu {
    width: 400px !important;
    height: 400px !important;
    overflow: auto;
  }

  .transfer-header:hover {
    border-color: rgb(30, 38, 109) !important;
    background-color: rgb(30, 38, 109) !important;
  }
}