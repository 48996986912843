.modal-wrap-schedule {
  margin-top: 200px;
  .schedule {
    z-index: 999 !important;
    background-color: rgb(241, 242, 246);
  }

  textarea {
    height: 120px;
  }
}
