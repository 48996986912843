.rbc-day-bg {
  background-color: white;
}

.rbc-off-range-bg {
  background-color: rgb(0, 0, 0, 0.03) !important;
}

.rbc-today {
  background-color: rgb(179, 206, 248) !important;
  color: white !important;
}

.rbc-btn-group {
  box-shadow: 0 0 50px rgb(221, 229, 238);
  button:hover {
    background-color: rgb(60, 131, 245) !important;
    color: white !important;
  }
}

.rbc-toolbar button.rbc-active {
  background-color: rgb(60, 131, 245) !important;
  color: white !important;
}

.rbc-button-link {
  color: rgb(32, 95, 196) !important;
}

.rbc-day-bg rbc-today {
  .rbc-button-link {
    color: white !important;
  }
}

.test {
  box-shadow: 0 2px 2px -2px rgba(223, 223, 223, 0.8);
}
