// Colors
$primary-orange: #F46A35;
$primary-blue: #1E266D;
$background-blue: #F1F5FE;

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: $background-blue;
    overflow-x: hidden;
}

.login-v3-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $background-blue;
    position: relative;
    overflow: hidden;

    // Top-left and bottom-right circles
    // &::before {
    //     content: "";
    //     position: absolute;
    //     border-radius: 50%;
    //     background-color: $primary-blue;
    //     z-index: 1;
    //     opacity: 1;
    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     border-radius: 50%;
    //     background-color: $primary-orange;
    //     z-index: 1;
    //     opacity: 1;
    // }

    // &::before {
    //     width: 550px;
    //     height: 550px;
    //     top: -150px;
    //     left: -150px;
    // }

    // &::after {
    //     width: 690px;
    //     height: 690px;
    //     bottom: -200px;
    //     right: -200px;
    // }

    // @media (max-width: 1400px) {
    //     &::after {
    //         width: 620px;
    //         height: 620px;
    //         bottom: -200px;
    //         right: -200px;
    //     }
    // }

    // @media (max-width: 1200px) {
    //     .login-v3-card {
    //         height: 1000px;
    //     }
    //     &::after {
    //         width: 570px;
    //         height: 570px;
    //         bottom: -200px;
    //         right: -200px;
    //     }
    // }

    // @media (max-width: 768px) {
    //     &::before {
    //         width: 250px;
    //         height: 250px;
    //         top: -100px;
    //         left: -100px;
    //     }

    //     &::after {
    //         width: 400px;
    //         height: 400px;
    //         bottom: -150px;
    //         right: -150px;
    //     }
    // }
}


.login-v3-card {
    display: flex;
    width: 80%;
    max-width: 1200px;
    height: 800px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.9);
    z-index: 2;
    overflow: hidden;
    position: relative;

    .left-section {
        flex: 1;
        background-color: $primary-blue;
        // background-color: rgba(30,38,109, 0.5);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        text-align: center;

        h2 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
        }
    }

    .right-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        background-color: rgba(241, 245, 254, 0.0);

        .login-form {
            width: 100%;
            max-width: 300px;

            .form-group {
                margin-bottom: 1.5rem;

                label {
                    display: block;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                }

                input {
                    width: 100%;
                    padding: 0.75rem;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                }

                input:focus {
                    outline: 1px solid $primary-orange;
                    border: 1px solid $primary-orange;
                }
            }

            .submit-btn {
                width: 100%;
                padding: 0.75rem;
                background-color: $primary-orange;
                color: #fff;
                font-weight: bold;
                border: none;
                border-radius: 10px;
                cursor: pointer;

                &:disabled {
                    background-color: lighten($primary-orange, 10%);
                    cursor: not-allowed;
                }
            }

            .submit-btn:hover {
                background-color: $primary-blue
            }

            .or-text {
                text-align: center;
                margin: 1rem 0;
                font-size: 0.9rem;
                color: $primary-blue;
            }

            .facebook-text {
                text-align: center;
                font-size: 0.9rem;
                color: $primary-orange;
                cursor: pointer;
            }
        }
    }
}

.skeleton-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .skeleton-logo {
        width: 100%;
        height: 80px;
        background-color: #e0e0e0;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }

    .skeleton-input {
        width: 100%;
        height: 32px;
        background-color: #a74747;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }

    .skeleton-button {
        width: 100%;
        height: 32px;
        background-color: #e0e0e0;
        border-radius: 10px;
        animation: skeleton-loading 1.5s infinite;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #f5f5f5;
    }

    100% {
        background-color: #e0e0e0;
    }
}

.customCopyright {
    font-size: 12px !important;
}

.phone-input-group {
    position: relative;
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }

    .phone-input-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        .phone-prefix {
            position: absolute;
            left: 10px;
            font-size: 1.25rem;
            font-weight: 500;
            color: #555;
        }

        input {
            padding-left: 25px !important;
            width: 100%;
            padding: 0.75rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1rem;
        }

        input::placeholder {
            color: #aaa;
        }
    }
}


//RESPONSIVE 
@media (max-width: 768px) {
    .login-v3-card {
        flex-direction: column;
        height: auto;
        max-height: 90vh; 
        overflow-y: auto; 
        -webkit-overflow-scrolling: touch; 

        .left-section {
            flex: none;
            width: 100%;
            padding: 1.5rem;
            order: 2;
            text-align: center;

            h3 {
                font-size: 1.2rem;
            }

            p {
                font-size: 0.9rem;
            }

            img {
                width: 120px;
            }
        }

        .right-section {
            flex: none;
            width: 100%;
            padding: 1.5rem;
            order: 1;

            .login-form {
                max-width: 100%;

                .form-group {
                    input {
                        padding: 0.5rem;
                    }
                }

                .submit-btn {
                    padding: 0.5rem;
                }

                .skeleton-loader {
                    max-width: 100%;
                }
            }
        }
    }
}

//Small devices
@media (max-width: 480px) {
    .login-v3-background {
        padding: 1rem;
    }

    .login-v3-card {
        width: 100%;
        max-height: 90vh; 
        overflow-y: auto; 
        -webkit-overflow-scrolling: touch;

        .left-section,
        .right-section {
            padding: 1rem;
        }

        .skeleton-loader {
            .skeleton-logo {
                height: 80px;
            }

            .skeleton-input,
            .skeleton-button {
                height: 35px;
            }
        }
    }
}