.step-1 {
  width: 75%;
  height: 200px;
  margin: auto;
  padding-bottom: 50px;
  position: relative;
}

.step-2 {
  width: 75%;
  margin: auto;
  padding-bottom: 20px;
  position: relative;
}
