* {
  --bs-modal-width: 900px;
  --bs-btn-padding-x: 1rem !important;
  --bs-btn-active-border-color: var(--bs-border-color-translucent) !important;
  --bs-dropdown-link-active-bg: transparent !important;

  --bs-btn-bg: #1E266D !important;
  --bs-btn-border-color: #1E266D !important;
  --bs-btn-hover-bg: #1E266D !important;
  --bs-btn-hover-border-color: #1E266D !important;
  --bs-btn-focus-shadow-rgb: 221, 121, 48 !important;
  --bs-btn-active-bg: #1E266D !important;
  --bs-btn-active-border-color: #1E266D !important;
  --bs-btn-disabled-bg: #243147 !important;
  --bs-btn-disabled-border-color: #1E266D !important;
  --bs-list-group-active-bg: #1E266D !important;
  --bs-table-bg: "transparent";

  // --bs-nav-link-color: #ff9142 !important;
}

.modal {
  background-color: rgb(0, 0, 0, 0.7);

  .modal-content {
    margin-top: 100px;
    border-radius: 52px !important;
    box-shadow: 0 0 20px rgb(30, 38, 109);

    .modal-header {
      border-top-left-radius: 50px !important;
      border-top-right-radius: 50px !important;
      border-bottom: none;
    }
  }

  .modal-body {
    border-radius: 50px !important;
    padding: 0;
  }
}

.container-lg {
  max-width: 1900px !important;
}

.login-screen-container {
  background-color: #F1F5FE;
}

.bg-custom-modal {
  background-color: #e43901;
}

.custom-danger {
  color: #e43901;
}

.modal-content-v2 {
  border-radius: 200px !important;
}

.form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: solid 1px #c0d3f5;
  background-color: transparent !important;
}

.modal-dialog {
  margin-top: 10% !important;
}

.modal-header {}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 224, 224);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4882e0 !important;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4881dd !important;
}

.bg-info {
  background: linear-gradient(90deg,
      #607d8b 0%,
      #607d8b 35%,
      #607d8b 100%) !important;
}

.btn-transparent {
  background: transparent;
  color: black;
  border: 0;
}

.btn-success {
  background-color: rgb(38, 219, 38) !important;
  // border-color: rgb(70, 243, 70) !important;
}

.btn-collapse {
  background: rgb(227, 227, 231) !important;
  color: rgb(0, 0, 0);
  border: 0;
}

.btn-orange {
  background: rgb(224, 177, 76);
  color: rgb(252, 252, 252);
  border: none;
}

.btn-orange:hover {
  background: rgb(212, 168, 71);
  color: rgb(252, 252, 252);
  border: none;
}

.btn-danger {
  background: rgb(240, 37, 37) !important;
}

.btn-transparent:hover {
  background: blue !important;
  color: rgb(255, 255, 255);
  border: 0;
}

.btn-transparent:hover {
  background: blue !important;
  color: rgb(255, 255, 255);
  border: 0;
}

.btn-secondary {
  background-color: rgb(199, 140, 91) !important;
  border-color: rgb(199, 140, 91) !important;
}

.download {
  color: #1E266D;
}

.info {
  color: #0e4abb;
}

.color-secondary {
  color: #205fc4;
}

.bg-active {
  background: #0142ab !important;
  color: white;
}

h1,
h2,
h3,
h4,
h4,
h6 {
  font-family: "Work Sans", sans-serif;
}

p {
  font-family: "Work Sans", sans-serif;
}

tr {
  // border: none !important;
  // border-style: hidden !important;
}

th {
  font-family: "Work Sans", sans-serif;
}

td {
  font-family: "Work Sans", sans-serif;
}

label {
  font-family: "Work Sans", sans-serif;
}

div {
  font-family: "Work Sans", sans-serif;
}

span {
  font-family: "Work Sans", sans-serif;
}

.border-bottom-active {
  border-bottom: solid #1E266D;
}

.w-6 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 80%;
}

.w-95 {
  width: 95%;
}

.custom-font-size {
  font-size: 1rem;
}

.bg-light {
  background: rgb(0, 0, 0, 0.03) !important;
}

.bg-main {
  background: #1E266D !important;
}

.text-white {
  color: rgb(255, 255, 255) !important;
}

.text-orange {
  color: rgb(247, 157, 116) !important;
}

.text-primary {
  color: rgb(30, 38, 109) !important;
}

.text-green {
  color: rgb(87, 201, 87) !important;
}

.text-grey {
  color: rgb(113, 116, 121) !important;
}

.fw-bold {
  font-weight: 100 !important;
}

.text-outgoing {
  color: #205fc4 !important;
}

.text-light-blue {
  color: rgb(102, 150, 228) !important;
}

.bg-light-blue {
  background-color: #deeaff !important;
}

.custom-rounded {
  border-radius: 120px !important;
}

.light-grey {
  color: rgb(177, 180, 182) !important;
}

.text-dark-blue {
  color: rgb(25, 68, 138) !important;
}

.bg-green {
  background-color: rgb(87, 201, 87) !important;
}