.sidebar-cont {
  height: 1285px;
  //   background: #0142ab;
  background: white;
  // transition: all 0.2s ease-out;
  transition-timing-function: ease-in;

  /* Quick on the way out */
  transition: 0.2s;

  /* Hide thing by pushing it outside by default */
  transform: translateY(0);

  // i {
  //   color: rgb(98, 109, 126) !important;
  // }

  .side-elements-selected {
    border-right: solid rgb(30, 38, 109);
    text-align: center;
  }
  .side-elements {
    border-right: solid transparent;
    text-align: center;
  }
  .selected-rounded {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    line-height: 20px;
    padding-top: 12px;
    margin: auto;
    // margin-right: 100px !important;
    background-color: rgb(30, 38, 109);
    // display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }
}

.side-footer {
  text-align: center;

  margin-top: 300%;

  .email-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 95px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    margin-left: 60px;
  }

  .email-popup {
    width: 350px;
    left: 90%;
    top: 0%;

    position: absolute;
    z-index: 999 !important;
  }
}
