.finish {
  color: rgb(91, 127, 184);
  margin-top: 15%;
  margin-bottom: 20%;
  .circle {
    width: 150px;
    height: 150px;
    border-radius: 90px;
    line-height: 200px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }
  .circle-mobile {
    width: 100px;
    height: 100px;
    border-radius: 90px;
    line-height: 100px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }
  .modal-wrapper {
    width: 100%;
    top: 0;
    text-align: center;
    height: 100vh;
    position: absolute;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);

    .specific-modal {
      z-index: 999 !important;
      width: 1000px;
      overflow: auto;
      height: auto;
      margin-top: 25%;
      background: white;
      border: solid 1px red;
      box-shadow: 0 0 20px rgb(233, 117, 117);
      border-radius: 50px !important;

      .header {
        background-color: red;
        width: 100%;
        height: 110px;
        border-top-right-radius: 50px !important;
        border-top-left-radius: 50px !important;
      }
    }
  }
}
