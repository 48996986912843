.color-picker {
  height: 30px;
  position: relative;
  width: 95px;
  margin-top: 5px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.color-picker input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.color-picker-round {
  height: 15px;
  position: relative;
  width: 15px;
  border-radius: 50%;
  margin-top: 5px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.color-picker-round input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.status-list {
  height: 200px;
  overflow: auto;
  position: relative;

  .update {
    background: white;
    border: solid white;
    height: 200px;
    position: absolute;
    z-index: 999 !important;
  }
}
