.left-side-container-blue {
  background-color: rgb(244, 106, 53);
  position: absolute;

  width: 40% important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.left-side-container-password {
  height: 100vh;
}

.primary-custom-color {
  color: #F46A35 !important;
}

.secondary-custom-color {
  color: #1E266D !important;
}

.login-container {
  overflow: hidden;

  .left-side-container {
    animation: mymove 8s;
    position: relative;
    height: 100vh;
  }

  .left-side {
    display: flex;
    z-index: 1;

    align-items: center;
    margin-top: 30%;

    .btn-login:hover {
      background-color: #f35f2d;
    }

    .btn-login:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    .bg-primary-loginbtn-custom-color {
      background-color: #F46A35 !important;
      border: none; 
      color: white; 
      padding: 10px 20px; 
    }
  }

  .right-side {
    background-image: url("../../assets/images/login5.jpg");
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100vh;
    // opacity: 0.9;
    position: relative;
    height: 100vh;
    display: flex;
    padding-left: 2%;
    padding-top: 9%;
  }

  @keyframes mymove {
    0% {
      left: 0px;
      top: 0px;
    }

    40% {
      left: 770px;
      top: 1100px;
    }

    100% {
      left: 0px;
      top: 0px;
      // left: 800px;
      // top: 1200px;
    }

    // from {
    //   background-color: red;
    // }
    // to {
    //   background-color: blue;
    //   height: 100px;
    // }
  }

  -webkit-animation-fill-mode: forwards;
}