.table-wrapper-home {
  height: 450px;
  width: 100%;

  background-color: white;

  overflow: auto;

  table {
    width: 95%;
    overflow: auto;
  }
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 90px;
    line-height: 35px;
    background-color: rgb(229, 236, 248);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }
  .history-content {
    overflow: auto;
    height: 350px;
  }
}
