.rotate-180-cw {
  -webkit-animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-180-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes rotate-180-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotate-180-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.rotate-180-cw-reverse {
  -webkit-animation: rotate-180-cw-reverse 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-180-cw-reverse 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@-webkit-keyframes rotate-180-cw-reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@keyframes rotate-180-cw-reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
