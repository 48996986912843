.approve {
  width: 200px;
  position: absolute;
  top: 40%;
  background-color: white;
  left: 25%;
  z-index: 1;
}

.company {
  .logo-img {
    width: 70px;
    height: 70px;
    border-radius: 14%;
    object-fit: cover;
  }

  .circle {
    width: 60px;
    height: 60px;
    border-radius: 90px;
    background-color: rgb(236, 235, 234);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }

  .noti-circle {
    width: 185px;
    height: 23px;
    position: absolute;
    background-color: rgb(32, 95, 196);
    display: table-cell;
    color: rgb(255, 255, 255);
    font-weight: 400;
    overflow: hidden;
    vertical-align: middle;
    top: 0px;
    left: 0px;
    cursor: pointer;
  }
}

.btn-notactive-filter {
  background-color: #1E266D !important;
  color: white !important;
  border: 1px solid #1E266D !important;
}

.btn-notactive-filter:hover {
  background-color: #29306d !important;
  border-color: #4854c2 !important;
}

.btn-active-filter {
  background-color: #F46A35 !important;
  color: white !important;
  border: 1px solid #F46A35 !important;
}

.btn-active-filter:hover {
  background-color: #f7642a !important;
  border-color: #f7642a !important;
}

.square-button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 250px;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;

  .square-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-align: center;
    padding: 0;
    line-height: 1;
  }

  .top-left {
    background-color: #1E266D;
  }

  .top-right {
    background-color: #6c757d;
  }

  .bottom-left {
    background-color: #28a745;
  }

  .bottom-right {
    background-color: #F46A35;
  }
}