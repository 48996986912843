.mobile-side {
  position: absolute;
  width: 130px;
  height: 100vh;
  z-index: 999 !important;

  .side-elements-selected {
    border-right: solid rgb(32, 95, 196);
    text-align: center;
  }
  .side-elements {
    border-right: solid transparent;
    text-align: center;
  }
  .selected-rounded {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    line-height: 25px;
    // padding-top: 12px;
    margin: auto;
    // margin-right: 100px !important;
    background-color: rgb(56, 108, 197);
    // display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;
  }

  .mobile-side-footer {
    text-align: center;

    margin-top: 25%;

    .email-circle {
      width: 30px;
      height: 50px;
      border-radius: 50%;
      line-height: 55px;
      background-color: rgb(255, 255, 255);
      text-align: center;
      font-weight: 600;
      overflow: hidden;
      margin-left: 60px;
    }

    .email-popup {
      width: 350px;
      left: 90%;
      top: 0%;
      z-index: 999 !important;
      position: absolute;
      z-index: 999 !important;
    }
  }
}
