.history-wrpper {
  height: 500px;
  width: 100%;
  margin: auto;
  background-color: white;

  border-radius: 30px;
  .dropdown-menu {
    margin-left: -50px !important;
    margin-top: 10px;
    .dropdown-item {
      width: 20% !important;
    }
  }
  table {
    width: 95%;

    // tbody {
    //   border-width: 1px;
    // }

    .status-item:hover {
      background-color: rgb(242, 245, 247);
    }
  }
}
