.short-investments {
  width: 95%;
  background: white;
  height: 450px;
  margin: auto;
  overflow: hidden;
  overflow: auto;
  .investment-photos {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
}
