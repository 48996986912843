.upload-file-input {
  height: 200px;
  width: 50%;
  text-align: center;

  input {
    width: 85%;
    height: 140px;
    border: solid;
    opacity: 0;
    z-index: 1 !important;
    position: absolute;
    left: 0;
    cursor: pointer;
  }

  .upload-icon {
    font-size: 50px;
    // position: absolute;
    top: 20px;
    bottom: 0px;
    cursor: pointer;
  }
}
