.home-wrapper {
  height: 1164px;
  overflow: auto;
}

.main {
  .transfer-button {
    border-radius: 30px !important;
  }

  .card-info {
    background: linear-gradient(90deg,
        rgb(30, 38, 109) 0%,
        rgb(32, 95, 196) 35%,
        rgb(30, 38, 109) 100%);
    border-radius: 10px;
    height: 170px;
  }
}

.main-in {
  background: rgb(245, 245, 245);

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 90px;
    background-color: rgb(174, 228, 219, 0.4);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;

    i {
      color: rgb(18, 173, 173) !important;
    }
  }

  .circle-outgoing {
    width: 50px;
    height: 50px;
    border-radius: 90px;
    background-color: rgba(231, 174, 170, 0.4);
    text-align: center;
    display: table-cell;
    font-weight: 600;
    overflow: hidden;
    vertical-align: middle;

    i {
      color: rgb(228, 80, 69) !important;
    }
  }
}

.total {
  width: 10px;
  height: 10px;
  // margin: 35%;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #205fc4;
}

.outgoing {
  width: 10px;
  height: 10px;
  margin: 35%;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: rgb(93, 209, 194);
}

.incoming {
  width: 10px;
  height: 10px;
  margin: 35%;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: rgb(31, 80, 170);
}

.history-row {
  border-radius: 10px;
}

.iban {
  border-top-right-radius: 30px;
}

.history-row:hover {
  background-color: rgb(230, 230, 219);
}

.short-transactions {
  height: 350px;
  overflow: auto;
}

.more-details {
  position: absolute;
  right: 0;
  bottom: 2.2%;
}

.more-details:hover {
  background-color: #1E266D !important;
}

.profile-info:hover {
  background-color: #1E266D !important;
}

.bg-primary-custom {
  background-color: rgb(244, 106, 53) !important;
}

.text-custom-blue-ub {
  color: rgba(30, 38, 109) !important;
}

.text-custom-orange-ub {
  color: rgb(244, 106, 53) !important;
}

/* Skeleton Loading Styles */
@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.skeleton-welcome,
.skeleton-main-info,
.skeleton-table,
.skeleton-doughnut-chart,
.skeleton-short-investments {
  border-radius: 10px;
  animation: skeleton-loading 1.5s infinite;
}

.skeleton-welcome {
  width: 100%;
  height: 60px;
  background-color: #e0e0e0;
}

.skeleton-main-info {
  width: 100%;
  height: 120px;
  background-color: #e0e0e0;
}

.skeleton-table {
  width: 100%;
  height: 250px;
  background-color: #e0e0e0;
}

.skeleton-doughnut-chart {
  width: 100%;
  height: 250px;
  background-color: #e0e0e0;
}

.skeleton-short-investments {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
}
