.news-img {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: 0.7s ease all;
  -webkit-transition: 0.7s ease all;
  -moz-transition: 0.7s ease all;
  cursor: pointer;
}

.news-img:hover {
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.invest-header {
}

.invest-body {
  background-color: #d7e2f7;
  .custom-round {
    position: absolute;
    z-index: 1000;
    border-bottom-left-radius: 80%;
    border-bottom-right-radius: 80%;
    top: 0;
    background-color: rgb(255, 255, 255);
    height: 50px;
    border: solid white;
    width: 100%;
  }
  .short-body {
    height: 100px;
  }
}

.contact {
  border-radius: 20px !important;
  font-size: 20px !important;
  width: 250px;
}

.body-wrapper {
  height: 320px;
  overflow: auto;
}

.why-invest {
  margin-top: 100px;
}
.why-image {
  width: 600px;
  height: 500px;
  margin: 1%;
  position: relative;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;

    top: -100px;
    border-radius: 20px;
  }
}

.video img {
  display: none;
}
