.step-1 {
  width: 75%;

  margin: auto;
  padding-bottom: 50px;
  position: relative;
}

.step-2 {
  margin: auto;

  padding-bottom: 20px;
  position: relative;
}

.between-accounts {
  color: white;
  width: 350px;
  height: 200px;
  padding-top: 90px;
  vertical-align: middle;
  display: table-cell;
  text-align: center;

  background: linear-gradient(
    90deg,
    rgb(17, 49, 102) 0%,
    rgb(32, 95, 196) 35%,
    rgb(55, 84, 131) 100%
  );
}

.transfer-type-button:hover {
  background-color: rgb(32, 95, 196);
  color: white;
}

.wire-frame-send:hover {
  background-color: rgb(32, 95, 196) !important;
  color: white !important;
}
